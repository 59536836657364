@import "variables";

.footer {
  background: #EDEDEC !important;

  @include media-breakpoint-down(sm) {
    padding: 0 15px;
  }
}

.footer-text {
  color: $main-black;
  font-size: 16px;
  font-weight: 250;
  line-height: 34px;
  letter-spacing: .03rem;

  @include media-breakpoint-down(sm) {
    font-size: 11px !important;
    line-height: 20px !important;
  }

  &--bold {
    font-weight: 400;
  }

  &--big {
    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: .04rem;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &--big-uppercase {
    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: .015rem;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &--small {
    font-size: 14px;
  }

  &--mobile {
    display: none;
    width: 100%;
    text-align: center;
    margin: 0 auto 42px;

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
}


// Newsletter
.footer-newsletter {
  text-align: center;
  width: 100%;
  margin: 77px auto 72px;

  @include media-breakpoint-down(sm) {
    margin: 41px auto;
  }
}

.footer-newsletter-title {
  margin-bottom: 10px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 7px;
  }
}

.footer-newsletter-text {
  margin-bottom: 7px;
}

.footer-newsletter-label {
  position: relative;
  display: block;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

.footer-newsletter-input {
  display: block;
  padding: 7px 37px 7px 7px;
  width: 100%;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: $grey;
  line-height: 20px;
  background: transparent;
  transition: .25s ease;

  &.active {
    border-width: 0 0 2px;
    transition: .25s ease;
  }

  &.wrong {
    border-color: $brand-color;
  }
}

.footer-newsletter-button {
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translateY(-50%);
  display: block;
  width: 7px;
  height: 14px;
  border: none;
  cursor: pointer;
  box-shadow: none;
  background: url(../images/arrows/icon_arrow_next_black.svg);
  background-size: contain;
  background-repeat: no-repeat;

  @include media-breakpoint-down(sm) {
    right: 0;
    width: 5px;
    height: 9px;
  }
}

// Links
.footer-links-list {
  width: 100%;
  border-width: 1px 0;
  border-color: $grey-light;
  border-style: solid;
  padding: 40px 0 32px 0;
  @include media-breakpoint-down(sm) {
    border: none
  }

}

@include media-breakpoint-down(sm) {
  .footer-links-box.active {

    h3 {
      color: rgba(28, 20, 23, 1);
    }

    .mobile_icon:before {
      transform: translateY(-50%) rotate(90deg);
      transition: transform .2s linear;

    }

    .footer-links-box__body {
      display: block;
      transition: all .4s linear;
      opacity: 1;
      margin-top: 10px;
      font-weight: 400;
      color: rgba(28, 20, 23, 0.4)
    }

  }


  .footer-links-box__body {
    display: none;
    opacity: 0;
  }
  .mobile_icon {
    position: relative;
    height: 20px;
    width: 100%;

    &:before {
      display: block;
      position: absolute;
      content: '';
      top: 10px;
      right: 10px;
      width: 16px;
      height: 16px;
      transform: translateY(-50%);
      transition: transform .2s linear;
      background: url(../images/arrows/icon_arrow_secondary.svg) no-repeat center center;
    }
  }
}

.footer-links-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;

  @include media-breakpoint-down(sm) {
    margin-top: 20px;
    grid-template-columns: 1fr;
    border-top: 1px solid #ddd;
  }
}

.footer-links-box {
  display: grid;
  width: 100%;
  color: #4F4A47;
  position: relative;
  justify-content: center;

  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;

  }
  @include media-breakpoint-down(sm) {
    padding: 16px 0;
    border-bottom: 1px solid #ddd;

  }


  a {
    margin-bottom: 8px;
    font-size: 14px;
  }

  h3 {
    color: #1C1417;
    font-weight: 600;
    margin-bottom: 20px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 8px;
      color: rgba(28, 20, 23, 0.4)
    }
  }
}

.footer-link {
  position: relative;
  display: block;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: .075rem;
  text-decoration: none;
  transition: .25s ease;

  @include media-breakpoint-down(sm) {
    margin-bottom: 10px;
    font-weight: 400;
  }

  &:hover {
    opacity: .5;
    transition: .25s ease;
  }

  &:last-child {
    padding-right: 0;

    &:after {
      display: none;
    }
  }
}

.footer-location {
  background: transparent;
  margin-bottom: 20px;
  margin-top: 5px;
}

.footer-location-link {
  border: none;
  cursor: pointer;
  display: block;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: .075rem;
  margin: 0 auto;
  padding-left: 32px;
  position: relative;
  text-decoration: none;
  transition: .25s ease;
  background: transparent;

  @include media-breakpoint-down(sm) {
    padding-left: 27px;
  }

  &:hover {
    opacity: .5;
    transition: .25s ease;
  }

  &:before {
    background: url("../images/icon-region-world.svg") center/contain no-repeat;
    content: '';
    display: block;
    height: 25px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;

    @include media-breakpoint-down(sm) {
      height: 20px;
      width: 20px;
    }
  }
}


// Socials
.footer-socials {
  margin: 36px auto 92px;
  width: 100%;

  @include media-breakpoint-down(sm) {
    margin: 20px auto 42px;
  }
}

.footer-socials-title {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: .05rem;
  margin-bottom: 17px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
  }
}

.footer-socials-container {
  display: flex;
  justify-content: center;
}

.footer-social {
  display: block;
  height: 25px;
  width: 25px;
  margin-right: 20px;
  background-size: contain !important;
  opacity: 1;
  cursor: pointer;
  transition: .25s ease;

  @include media-breakpoint-down(sm) {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  &:hover {
    opacity: .5;
    transition: .25s ease;
  }

  &:last-child {
    margin-right: 0;
  }

  &--fb {
    background: url('../images/socials/icon_fb_grey_dark.svg') no-repeat;
  }

  &--inst {
    background: url('../images/socials/icon_instagram_grey_dark.svg') no-repeat;
  }

  &--in {
    background: url('../images/socials/icon_in_grey_dark.svg') no-repeat;
  }

  &--pin {
    background: url('../images/socials/icon_pinterest_grey_dark.svg') no-repeat;
  }

  &--tw {
    background: url('../images/socials/icon_x_grey_dark.svg') no-repeat;
  }
}


// Footer title
.footer-title {
  font-weight: 600;
  text-align: center;
  margin-bottom: 54px;
  width: 100%;

  @include media-breakpoint-down(sm) {
    margin-bottom: 38px;
  }
}

// Bottom bar
.footer-bottom-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column-reverse;
  }

  &--background {
    background: #E3E3E3;
    width: 100%;
    padding: 24px 0;
    @include media-breakpoint-down(sm) {
      position: relative;
      z-index: 160000004;
      margin: 0 -15px;
      width: calc(100% + 30px);
    }
  }
}

.footer-copyright {
  display: inline-grid;

  @include media-breakpoint-down(sm) {
    margin-top: 32px;
  }
}

.footer-bottom-bar__box {
  display: inline-block;
  width: fit-content;
  padding-right: 10px;
  @include media-breakpoint-down(sm) {
    margin-right: 0;
    padding-right: 0;
  }

  &--right {
    text-align: right !important;
    width: 100%;
    color: red;

    .footer-sitemap-btn {
      text-align: right !important;
    }
  }
}

.footer-bar-link {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  color: rgba(28, 20, 23, 0.4);
  text-decoration: none;

  &:hover {
    opacity: .5;
    transition: .25s ease;
  }
}

.no-link {
  &:hover {
    opacity: 1;
    transition: .25s ease;
  }
}

.footer-bottom-bar-box {
  display: none;
  flex-grow: 1;
  justify-content: flex-end;
  @include media-breakpoint-down(sm) {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-left: 0;
    flex-grow: 0;

  }
}

.footer-bottom-bar-column {
  display: flex;
  @include media-breakpoint-down(sm) {
    width: 50%;

    gap: 10px;
    flex-direction: column;
  }
}

.footer-sitemap-btn {
  text-align: center;
  cursor: pointer;
  transition: .25s ease;

  &:hover {
    opacity: .5;
    transition: .25s ease;
  }

  &.active {
    font-weight: 600;
  }
}

.footer-contact {
  display: inline-flex;
  flex-wrap: wrap;
  //margin: 0 0 14px auto;

  @include media-breakpoint-down(sm) {
    margin: 0 0 14px;
  }
}

.footer-contact-element {
  &:first-child {
    margin-right: 50px;
  }
}

.footer-phone,
.footer-mail {
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.footer-phone,
.footer-mail {
  @include media-breakpoint-down(sm) {
    font-size: 9px;
    line-height: 11px;
    letter-spacing: .03rem;
  }
}

// Footer sitemap
.footer-sitemap {
  display: none;
  padding: 20px 0;
  border-top: 1px solid $grey-dark;

  @include media-breakpoint-down(sm) {
    padding: 11px 0;
  }
}

.footer-sitemap-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-template-areas: 'products inspiration information' 'resources insights .';
  grid-gap: 32px;
  margin-bottom: 85px;

  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
    grid-template-areas: 'products' 'resources' 'inspiration' ' insights' 'information';
    grid-gap: 16px;
    margin-bottom: 34px;
  }
}

.footer-sitemap-box {
  &--products {
    grid-area: products;
  }

  &--inspiration {
    grid-area: inspiration;
  }

  &--information {
    grid-area: information;
  }

  &--resources {
    grid-area: resources;
  }

  &--insights {
    grid-area: insights;
  }
}

.footer-sitemap-title {
  color: $black;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: .03rem;
  margin-bottom: 5px;

  @include media-breakpoint-down(sm) {
    font-size: 18px;
    margin-bottom: 1px;
  }
}

.footer-sitemap-link {
  color: $dark-grey;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: .03rem;
  text-decoration: none;
  display: block;
  transition: .25s ease;

  @include media-breakpoint-down(sm) {
    font-size: 11px;
    line-height: 16px;
  }

  &:hover {
    opacity: .5;
    transition: .25s ease;
  }
}

.footer-bottom-bar-box-web {
  display: flex;
  gap: 14px;
  @include media-breakpoint-down(sm) {
    display: none;
  }
}
